import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ImprovementComponent } from './improvement/improvement.component';
import { MatchComponent } from './match/match.component';
import { PolicyComponent } from './policy/policy.component';
import { TermsComponent } from './terms/terms.component';
import { DashboardComponent} from './dashboard/dashboard.component';
import { AuthGuard } from './guard/auth.guard';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoginComponent } from './login/login.component';
import { LoggedInGuard } from './guard/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'match/:id',
    component: MatchComponent
  },
  {
    path: 'improve/:resumeId/:jobId',
    component: ImprovementComponent
  },
  {
    path: 'dashboard/:id',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'password-reset/:token',
    component: PasswordResetComponent
  },
  {
    path: 'email-verify',
    component: EmailVerifyComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'policy',
    component: PolicyComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
