import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../constants';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'jobstarter-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  token: any;
  email: any;
  passwordMatch = false;
  newPassword = '';
  confirmedPassword = '';
  resetFailed: any = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.getPageData();
    this.pageGuard();
  }

  getPageData() {
    this.token = this.route.snapshot.paramMap.get('token') ? this.route.snapshot.paramMap.get('token') : null;
    this.email = this.route.snapshot.queryParamMap.get('email') ? this.route.snapshot.queryParamMap.get('email') : null;
  }

  pageGuard() {
    if (!this.email) {
      this.router.navigate([Constants.PAGE_HOME]);
    }
  }

  backToLogin() {
    this.router.navigate([Constants.PAGE_LOGIN]);
  }

  inputChange(event: any) {
    if (event.target.id === 'newPassword') {
      this.newPassword = event.target.value;
    } else {
      this.confirmedPassword = event.target.value;
    }
    if (this.newPassword && this.confirmedPassword) {
      this.passwordMatch = this.newPassword === this.confirmedPassword;
    }
  }

  async resetPassword() {
    const formData = new FormData();
    formData.append('email', this.email);
    formData.append('token', this.token);
    formData.append('password', this.newPassword);
    try {
      await this.http.post(Constants.API_HOST + 'resetPassword',
        formData).toPromise();
      this.resetFailed = false;
    } catch (error) {
      console.log(error);
      this.resetFailed = true;
    }
  }

}
