<div class="container">
    <jobstarter-header></jobstarter-header>
    <div class="row start-row pt-5 pb-5 bg-blue">
        <div class="col-md-4 offset-md-4 bg-main text-center p-5">
            <h1 class="color-orange">{{average}}%</h1>
            <h3>Total Score</h3>
            <div class="mt-5 text-justify">
                <ng-container *ngIf="average >= 75">
                    <p>Congratulations! Your resume is on the top list and you have high chance to get an interview
                        when you apply jobs</p>
                    <p>However, there are still spaces for improving your resume and make it perfect, please click
                        "improve now" to check details of how your resume can be better.
                    </p>
                </ng-container>
                <ng-container *ngIf="average >= 50 && average < 75">
                    <p>Good! Your resume is above average of other candidates and you are likely selected for
                        interviews.</p>
                    <p>However, "Above average" does not guarantee anything, you need to improve your resume to be more
                        competitive.
                    </p>
                </ng-container>
                <ng-container *ngIf="average >= 25 && average < 50">
                    <p>Improvement Needed! You resume is below average of other candidates and it is slightly hard to
                        get an interview if you apply jobs.</p>
                    <p>Your resume still have potentials. However, editing your resume is highly recommended before
                        apply any jobs.
                    </p>
                </ng-container>
                <ng-container *ngIf="average < 25">
                    <p>Resume Restructure Needed! Unfortunately, your resume is below 25% of other candidates. You are
                        facing great challenge to get an interview if you start to apply jobs.</p>
                    <p>YYour resume needs significant editing to be ready for applying jobs.</p>
                </ng-container>
            </div>
            <button class="btn-default btn-orange mt-5" (click)="goToImprovmentPage()">IMPROVE YOUR SCORE</button>
            <div class="mt-4 d-none d-block d-sm-block d-md-none">
                <button type="button" class="btn btn-light" (click)="collapse.toggle()"
                    [attr.aria-expanded]="!isCollapsed">
                    Filter
                </button>
            </div>
        </div>
    </div>
    <div class="row content-row pt-5">
        <div class="col-md-3 filter-section mb-3" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Job title, keywords or company"
                    (keyup)="onPositionSearch($event)">
                <div class="input-group-append">
                    <span class="input-group-text">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                            <path fill-rule="evenodd"
                                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                        </svg>
                    </span>
                </div>
            </div>
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="All Locations" (keyup)="onLocationSearch($event)">
                <div class="input-group-append">
                    <span class="input-group-text">
                        <fa-icon [icon]="faCrosshairs"></fa-icon>
                    </span>
                </div>
            </div>
            <div class="status-filter mb-3">
                <p class="text-dark">Job Status</p>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="permanent"
                        (change)="checkJobStatus($event, 'Permanent')">
                    <label class="form-check-label" for="permanent">
                        Permanent
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="internship"
                        (change)="checkJobStatus($event, 'Internship')">
                    <label class="form-check-label" for="internship">
                        Internship
                    </label>
                </div>
            </div>
            <div class="category-filter">
                <p class="text-dark">TOP Matched Job Category</p>
                <div *ngIf="loadingSampleJobCategory" class="text-center">
                    <div class="spinner-border text-primary">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <ng-container *ngIf="!loadingSampleJobCategory">
                    <ng-container *ngFor="let sampleJob of sampleJobs">
                        <div *ngIf="sampleJob.show" class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="{{sampleJob.position}}"
                                (change)="checkValue($event, sampleJob.position, sampleJob)"
                                [checked]="sampleJob.checked">
                            <label class="form-check-label" for="{{sampleJob.position}}">
                                {{sampleJob.position}}
                            </label>
                            <span class="color-orange float-right">{{sampleJob.percentage}}/100</span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="input-group category-filter-input">
                <input type="text" class="form-control" placeholder="Filter Your Dream Categories"
                    [disabled]="loadingSampleJobCategory" (input)="onSampleJobCategorySearch($event)">
                <div class="input-group-append">
                    <span class="input-group-text">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-funnel" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-9 listing-section">
            <div class="listing-header d-md-flex">
                <h3 class="col-md-6 text-dark">TOP MATCHED JOBS</h3>
                <div class="sort-section col-md-6 text-right mb-2">
                    <span>Sort by</span>
                    <select class="form-control ml-3" (change)="sortChange($event)">
                        <option>Relevance</option>
                        <option>Date</option>
                    </select>
                </div>
            </div>
            <div *ngIf="loadingListingJobCategory" class="text-center">
                <!-- <div class="spinner-border text-primary">
                    <span class="sr-only"></span>
                </div> -->
                <div class="col-md-6 offset-md-3">
                    <lottie-player autoplay loop class="m-auto"
                        src="https://assets1.lottiefiles.com/packages/lf20_imurhxcq.json"></lottie-player>
                    <ng-container *ngIf="random == 0">
                        <p>It is essential to be sure that your LinkedIn profile is total, in-depth, fascinating, and
                            understandable.</p>
                        <p>In fact, you need to consider your LinkedIn profile your online resume.</p>
                        <p>It must have all the same information that is on your resume and more. You ought to add a
                            picture (a headshot) to your LinkedIn profile.</p>
                        <p>Ensure the picture represents the "professional you," instead of the "casual you." </p>
                        <p>LinkedIn isn't the place to display your pet dog or loved one. </p>
                        <p>Don't forget to make your profile public - that's how the world can find it. </p>
                        <p>Also, personalizing your URL will offer you a link that's simple to share on your resume and
                            with employer and connections. </p>
                        <p>If your name is available, use it.</p>
                    </ng-container>
                    <ng-container *ngIf="random == 1">
                        <p>The Summary (About) section of your LinkedIn profile is a terrific method to highlight what
                            makes you special and indispensable to your market. </p>
                        <p>Do not forget the heading, considering that it is right at the top of the page when someone
                            views your profile. </p>
                        <p>If suitable, it is appropriate to discuss crucial professional certifications, bilingual
                            skills, or essential achievements. </p>
                        <p>The more robust your profile, the more you likely you are to get notice. Select a market, due
                            to the fact that employers typically use that field to browse.</p>
                    </ng-container>
                    <ng-container *ngIf="random == 2">
                        <p>A resume objective, sometimes called a career objective, is a one- or two-sentence overview
                            of your professional goals and why you're looking for a job. </p>
                        <p>It helps you show why you're the best candidate for the role. </p>
                        <p>Place your objective just below your header to quickly get the hiring manager's attention and
                            make them want to read your resume</p>
                    </ng-container>
                    <ng-container *ngIf="random == 3">
                        <p>6 Steps of preparing your resume objective</p>
                        <p>1) Keep it concise</p>
                        <p>2) Tailor it to the position</p>
                        <p>3) Set the tone for the rest of your resume</p>
                        <p>4) Lead with your strongest attributes</p>
                        <p>5) Note any certifications, licenses and degrees that are relevant to the position</p>
                        <p>6) Describe how you can add value as an employee</p>
                    </ng-container>
                    <ng-container *ngIf="random == 4">
                        <p>Knowing what possible reasons may be preventing you from moving forward in the hiring process
                            will help motivate you during your job search. </p>
                        <p>When you have an understanding of the areas you can improve upon, you can take action to
                            become a better candidate impress hiring managers. </p>
                        <p>Additionally, knowing which areas you can refine will help guide you in the right direction
                            during your job search and reduce confusion, making the journey more enjoyable</p>
                    </ng-container>
                    <ng-container *ngIf="random == 5">
                        <p>ATS keywords on a resume are necessary because the applicant tracking system quickly scans
                            numerous resumes, and then it ranks candidates based on keywords chosen by the company for
                            the readily available position. </p>
                        <p>This suggests your resume has to effectively travel through the ATS prior to it reaching the
                            employer or working with a supervisor for consideration. </p>
                        <p>Enhancing your resume for ATS keywords can assist increase your possibility of a hiring
                            supervisor seeing your resume and contacting you for a preliminary phone screen or interview
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="job-listing" *ngIf="!loadingListingJobCategory">
                <jobstarter-job-listing [items]="listingJobs" [resumeId]="resumeId" [isLoggedIn]="isLoggedIn">
                </jobstarter-job-listing>
            </div>
        </div>
    </div>
    <jobstarter-footer></jobstarter-footer>
</div>