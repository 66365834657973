import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { ReplaySubject, Subscription } from 'rxjs';
import { Constants } from '../constants';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'jobstarter-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss']
})
export class MatchComponent implements OnInit, OnDestroy {
  faCrosshairs = faCrosshairs;
  searchPositionString = '';
  searchPositionChange = new ReplaySubject<string>(1);
  searchPositionSub: Subscription = new Subscription();
  searchLocationString = '';
  searchLocationChange = new ReplaySubject<string>(1);
  searchLocationSub: Subscription = new Subscription();
  page = 1;
  jobStatus = new Set();
  fields = new Set();
  resumeId: any;
  sampleJobs: any[] = [];
  sampleJobsBackup: any[] = [];
  listingJobs: any[] = [];
  listingJobsBackup: any[] = [];
  loadingSampleJobCategory = true;
  loadingListingJobCategory = true;
  average = 0;
  isCollapsed = false;
  isLoggedIn = false;
  sessionStorageKey = '';
  sessionFieldsKey = '';
  random = Math.floor(Math.random() * 6);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  async initialize() {
    this.subscribeToTextSearch();
    this.initPageData();
    await this.getSampleJobPercentage();
    await this.getListingJobPercentage();
  }

  initPageData() {
    this.page = this.route.snapshot.queryParams.page ? this.route.snapshot.queryParams.page : 1;
    this.resumeId = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '';
    this.isLoggedIn = this.authService.isLoggedIn();
    this.sessionStorageKey = 'Match-' + moment().format('YYYYMMDD') + this.resumeId;
    this.sessionFieldsKey = 'CheckedFileds-' + this.resumeId;
    if (sessionStorage.getItem(this.sessionFieldsKey)) {
      this.fields = new Set(JSON.parse(sessionStorage.getItem(this.sessionFieldsKey) || '{}'));
    }
    setInterval(() => {
      this.random = (this.random + 1) % 6;
    }, 30000);
  }

  async getSampleJobPercentage() {
    try {
      const res: any = await this.http.get(Constants.API_HOST + 'getSampleJobPercentage',
        { params: { resumeId: this.resumeId } }).toPromise();
      this.sampleJobsBackup = [...res.items];
      this.sampleJobs = [...res.items];
      this.sampleJobs.forEach(sj => {
        sj.checked = this.fields.has(sj.position);
        sj.show = true;
        sj.percentage = sj.percentage < 90 && sj.percentage > 20 ? Math.min(sj.percentage + 20, 89) : sj.percentage;
      });
      this.average = Math.ceil((this.sampleJobs[0].percentage + this.sampleJobs[1].percentage + this.sampleJobs[2].percentage) / 3);
      this.loadingSampleJobCategory = false;
    } catch (error) {
      console.log(error);
    }
  }

  async getListingJobPercentage() {
    let res: any;
    if (sessionStorage.getItem(this.sessionStorageKey)) {
      res = JSON.parse(sessionStorage.getItem(this.sessionStorageKey) || '{}');
      this.listingJobsBackup = [...res.items];
      this.filterListingJob();
    } else {
      const params: any = {};
      params.resumeId = this.resumeId;
      params.limit = 100;
      params.withLink = this.isLoggedIn;
      // try {
      //   res = await this.http.get(Constants.API_HOST + 'getListingJobPercentage',
      //     { params: params }).toPromise();
      //   this.listingJobsBackup = [...res.items];
      //   this.filterListingJob();
      // } catch (error) {
      //   console.log(error);
      // }
      delete params.limit;
      try {
        res = await this.http.get(Constants.API_HOST + 'getListingJobPercentage',
          { params }).toPromise();
        sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(res));
        this.listingJobsBackup = [...res.items];
        this.filterListingJob();
      } catch (error) {
        console.log(error);
      }
    }
  }

  filterListingJob() {
    this.loadingListingJobCategory = true;
    let listingJobs = [...this.listingJobsBackup];
    this.listingJobs = listingJobs;

    if (this.fields.size) {
      this.listingJobs = listingJobs.filter((job) => {
        for (const field of job.fields) {
          if (this.fields.has(field)) {
            return true;
          }
        }
        return false;
      });
      listingJobs = this.listingJobs;
    }

    if (this.jobStatus.size) {
      this.listingJobs = listingJobs.filter((job) => {
        if (this.jobStatus.has(job.jobType)) {
          return true;
        } else {
          return false;
        }
      });
      listingJobs = this.listingJobs;
    }

    if (this.searchPositionString.trim() !== '') {
      this.listingJobs = listingJobs.filter((job) => {
        if (job.position.toLowerCase().includes(this.searchPositionString.toLowerCase())) {
          return true;
        }
        return false;
      });
      listingJobs = this.listingJobs;
    }

    if (this.searchLocationString.trim() !== '') {
      this.listingJobs = listingJobs.filter((job) => {
        if (job.location.toLowerCase().includes(this.searchLocationString.toLowerCase())) {
          return true;
        }
        return false;
      });
      listingJobs = this.listingJobs;
    }

    setTimeout(() => {
      this.loadingListingJobCategory = false;
    }, 100);
  }

  onSampleJobCategorySearch(event: any) {
    const searchString = event.target.value;
    this.sampleJobs.forEach(sj =>
      sj.position.toLowerCase().includes(searchString.toLowerCase()) ? sj.show = true : sj.show = false);
  }

  checkValue(event: any, searchField: string, sampleJob: any) {
    if (event.target.checked) {
      this.fields.add(searchField);
      sampleJob.checked = true;
    } else {
      this.fields.delete(searchField);
      sampleJob.checked = false;
    }
    console.log(this.fields);
    sessionStorage.setItem(this.sessionFieldsKey, JSON.stringify(Array.from(this.fields)));
    this.filterListingJob();
  }

  subscribeToTextSearch() {
    this.searchPositionSub = this.searchPositionChange.pipe(debounceTime(500))
      .subscribe((e) => this.searchPosition(e));
    this.searchLocationSub = this.searchLocationChange.pipe(debounceTime(500))
      .subscribe((e) => this.searchLocation(e));
  }

  ngOnDestroy() {
    if (this.searchPositionSub) {
      this.searchPositionSub.unsubscribe();
    }
    if (this.searchLocationSub) {
      this.searchLocationSub.unsubscribe();
    }
  }

  searchPosition(searchPositionString: string) {
    this.searchPositionString = searchPositionString;
    this.filterListingJob();
  }

  onPositionSearch(event: any) {
    this.searchPositionChange.next(event.target.value);
  }

  searchLocation(searchLocationString: string) {
    this.searchLocationString = searchLocationString;
    this.filterListingJob();
  }

  onLocationSearch(event: any) {
    this.searchLocationChange.next(event.target.value);
  }

  checkJobStatus(event: any, status: string) {
    if (event.target.checked) {
      this.jobStatus.add(status);
    } else {
      this.jobStatus.delete(status);
    }
    this.filterListingJob();
  }

  sortChange(event: any) {
    this.loadingListingJobCategory = true;
    if (event.target.value === 'Relevance') {
      this.listingJobs = this.listingJobs.sort((a, b) => b.percentage - a.percentage);
    } else {
      this.listingJobs = this.listingJobs.sort((a, b) => moment(a.postTimestamp).isBefore(moment(b.postTimestamp)) ? 1 : -1);
    }
    setTimeout(() => {
      this.loadingListingJobCategory = false;
    }, 100);
  }

  goToImprovmentPage() {
    this.router.navigate([Constants.PAGE_IMPROVE + this.resumeId + '/' + this.sampleJobsBackup[0].id]);
  }
}
