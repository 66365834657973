<div class="container vh-100">
    <jobstarter-header></jobstarter-header>
    <div class="row start-row pt-5 bg-blue text-white h-100">
        <div class="col-md-8 offset-md-2 text-center mt-5">
            <img src="assets/images/email-verified.png" />
            <h3 class="mt-5">
                Your e-mail address has been verified
            </h3>
            <button class="btn-default mt-5" (click)="openModal()">Continue to Jobstarter</button>
        </div>
    </div>
    <jobstarter-footer></jobstarter-footer>
</div>