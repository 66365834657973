import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../constants';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'jobstarter-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userName = '';
  userId: any;;
  infoList: any;
  loadingResumes = true;
  config: any = {
    itemsPerPage: 6,
    currentPage: 1,
  };
  labels: any = {
    previousLabel: '',
    nextLabel: '',
    totalItems: 0
  };

  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.initialize();
  }

  async initialize() {
    await this.getPageData();
    this.getUserResumes();
  }

  getPageData() {
    this.userId = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '';
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    this.userName = user.firstName;
  }

  async getUserResumes() {
    try {
      this.infoList = await this.http.get(Constants.API_HOST + 'getResumesByUserId', { params: { userId: this.userId } }).toPromise();
      this.infoList.items.forEach((resume: any) => {
        resume.percentage = resume.percentage < 90 && resume.percentage > 20 ? Math.min(resume.percentage + 20, 89) : resume.percentage;
      });
      this.config.totalItems = this.infoList.count;
    } catch (error) {
      console.log(error);
      this.config.totalItems = 0;
    }
    this.loadingResumes = false;
  }

  goToMatchPage(resumeid: any) {
    this.router.navigate(['/match/', resumeid]);
  }

  goToImprovePage(resumeid: any, jobid: any) {
    this.router.navigate(['/improve/', resumeid, jobid]);
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
  }

  logout() {
    this.authService.logout();
  }
}
