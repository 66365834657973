import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../constants';
import { LoginComponent } from '../modal/login/login.component';

@Component({
  selector: 'jobstarter-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {
  email: any;
  token: any;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.getPageData();
    this.verifyEmail();
  }

  getPageData() {
    this.email = this.route.snapshot.queryParamMap.get('email') ? this.route.snapshot.queryParamMap.get('email') : null;
    this.token = this.route.snapshot.queryParamMap.get('token') ? this.route.snapshot.queryParamMap.get('token') : null;
  }

  async verifyEmail() {
    const formData = new FormData();
    formData.append('email', this.email);
    formData.append('token', this.token);
    try {
      const res: any = await this.http.post(Constants.API_HOST + 'email-verify',
        formData).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  openModal() {
    this.modalService.open(LoginComponent);
  }
}
