<div class="modal-header">
    <h4 class="modal-title">Let job matches your resume</h4>
</div>
<div class="modal-body">
    <ngx-dropzone (change)="onSelect($event)" accept=".pdf,.doc,.docx,.txt" [multiple]=false>
        <ngx-dropzone-label class="dropzone-content pt-3 pb-3">
            <img src="assets/images/file-upload-icon.png" width="100">
            <div class="mt-2">
                <span class="color-orange">Choose a file</span> or drop it here
            </div>
            <div class="mt-2">
                Supports pdf. doc. docx. txt
            </div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone>
</div>
<div class="modal-footer">
    <div class="mr-4">
        <input type="checkbox" class="mr-2" [checked]="isChecked" (change)="toggleCheckbox()">
        <span>I understand <a href="/terms" target="_blank">terms</a> & <a href="/policy" target="_blank">policy</a></span>
    </div>
    <button type="button" class="btn-default btn-orange" (click)="uploadFile()" [disabled]="disableSubmit">Submit</button>
</div>