<div class="row footer-row pt-5 pb-4 pl-sm-5 pl-md-0">
    <div class="col-md-6 offset-md-2">
        <img src="assets/images/jobstarter_orange.png" width="250">
        <div class="row mt-4">
            <div class="col-md-3 mb-1">
                <a href="/terms" class="text-dark">Terms & Condition</a>
            </div>
            <div class="col-md-3 mb-1">
                <a href="/policy" class="text-dark">Privacy Policy</a>
            </div>
            <div class="col-md-6 mb-1">
                All Rights Reserved @2021 JOBSTER
            </div>
        </div>
    </div>
    <div class="col-md-4 mt-5 mt-md-0">
        <h3>Sitemap</h3>
        <div class="mt-md-2 mb-1">
            <a href="/" class="text-secondary">Home</a>
        </div>
        <div class="mt-md-2">
            <a href="/contact-us" class="text-secondary">Contact us</a>
        </div>
    </div>
</div>