import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Constants } from '../constants';
import { LoginComponent } from '../modal/login/login.component';
@Component({
  selector: 'jobstarter-job-listing',
  templateUrl: './job-listing.component.html',
  styleUrls: ['./job-listing.component.scss']
})
export class JobListingComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() resumeId: any;
  @Input() isLoggedIn = false;
  currentListing: any[] = [];
  config: any = {
    itemsPerPage: 50,
    currentPage: 1,
  };
  labels: any = {
    previousLabel: '',
    nextLabel: '',
    totalItems: 0
  };
  constructor(
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.currentListing = this.items;
    const today = moment();
    const yesterday = moment().subtract(1, 'day');
    this.items.forEach(item => {
      if(moment(item.postTimestamp).isSame(today, 'day')) {
        item.formatedPostTimestamp = 'Today';
      } else if (moment(item.postTimestamp).isSame(yesterday, 'day')) {
        item.formatedPostTimestamp = 'Yesterday';
      } else {
        item.formatedPostTimestamp = moment(item.postTimestamp, 'YYYY-MM-DD').endOf('day').fromNow();
      }

      item.percentage = item.percentage < 90 && item.percentage > 20 ? Math.min(item.percentage + 20, 89) : item.percentage;
    });
    this.config.totalItems = this.currentListing.length;
  }

  goToJobPage(url: string) {
    if (this.isLoggedIn) {
      window.open(url, '_blank');
    } else {
      this.openLogin();
    }
  }

  goToImprovmentPage(jobId: string) {
    if (this.isLoggedIn) {
      this.router.navigate([Constants.PAGE_IMPROVE + this.resumeId + '/' + jobId]);
    } else {
      this.openLogin();
    }
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
  }

  openLogin() {
    this.modalService.open(LoginComponent);
  }
}
