import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: SocialAuthService
  ) { }

  async validUser() {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    if (user) {
      try {
        const res: any = await this.http.get(Constants.API_HOST + 'getUserToken', { params: { id: user.id } }).toPromise();
        if (res && user.deviceIdentity && res === user.deviceIdentity) {
          return user.id;
        }
      } catch (error) {
        console.log(error);
      }
    }
    return false;
  }

  isLoggedIn() {
    return localStorage.getItem('user') ? true : false;
  }

  getUserName() {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    if (user.firstName) {
      return user.firstName;
    }
    return;
  }

  getUserId() {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    if (user.id) {
      return user.id;
    }
    return;
  }

  isRegular() {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    return user.type === 'regular';
  }

  logout() {
    if (!this.isRegular()) {
      this.authService.signOut(true);
    }
    localStorage.removeItem('user');
    this.router.navigate([Constants.PAGE_HOME]);
  }
}
