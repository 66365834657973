<div class="row pt-0 ml-0 mr-0 btn-group-toggle" data-toggle="buttons">
    <button #focusSignin class="col-md-6 btn text-dark shadow-none p-3 border-0 rounded-0 btn-light"
        [class.active-button]="signinPage || resetPage" (click)="switchPage(SIGNIN)">
        Sign In
    </button>
    <button class="col-md-6 btn text-dark shadow-none p-3 border-0 rounded-0 btn-light"
        [class.active-button]="signupPage" (click)="switchPage(SIGNUP)">
        New to Jobstarter? Sign Up
    </button>
</div>
<div class="modal-body p-4">
    <div class="login-signup-container">
        <div *ngIf="sentVerification" class="row my-3">
            <div class="col-md-10 offset-md-1 bg-success text-white p-2">
                A verification email has been sent to your email address {{formInfo.get('email')}}
            </div>
        </div>
        <div *ngIf="sentReset" class="row my-3">
            <div class="col-md-10 offset-md-1 bg-success text-white p-2">
                A reset password email has been sent to your email address {{formInfo.get('email')}}
            </div>
        </div>
        <ng-container *ngIf="signupPage">
            <div class="form-group">
                <label for="firstNameInput">Full Name</label>
                <input type="text" class="form-control" id="firstNameInput" (change)="fieldChange($event, 'firstname')">
            </div>
        </ng-container>
        <ng-container *ngIf="!resetPage">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="emailInput">Email Address</label>
                        <input type="email" class="form-control" id="emailInput"
                            (change)="fieldChange($event, 'email')">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="passwordInput">Password</label>
                        <input type="password" class="form-control" id="passwordInput"
                            (change)="fieldChange($event, 'password')">
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="resetPage">
            <div class="row">
                <div class="col-md-12">
                    <p><small>Enter your email address and we will send you a link to rest the password.</small></p>
                    <div class="form-group">
                        <label for="emailInput">Email Address</label>
                        <input type="email" class="form-control" id="emailInput"
                            (change)="fieldChange($event, 'email')">
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="errorsignin">
            <div class="row">
                <div class="ml-3 mb-3">
                    <p class="mb-0 text-danger">Incorrect password or email address</p>
                    <p class="mb-0 text-danger">Please reset your password or create a new account</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="errorsignup">
            <div class="row">
                <div class="ml-3 mb-3">
                    <p class="mb-0 text-danger">{{signupErrorMessage}}</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="errorReset">
            <div class="row">
                <div class="ml-3 mb-3">
                    <p class="mb-0 text-danger">We are not able to find your email</p>
                </div>
            </div>
        </ng-container>
        <div class="row text-center mt-4">
            <div class="col-md-12">
                <button class="btn-default btn-orange btn-block btn-lg" type="button" (click)="sendData()"
                    [disabled]="sentVerification">{{pageName}}</button>
            </div>
        </div>

        <div class="row d-none">
            <div class="col-md-12 text-center">
                <button class="bg-transparent mb-3 w-100 p-2" (click)="signInWithGoogle()">
                    <img class="float-left ml-3" src="assets/images/Icon awesome-google.png">
                    <div class="mr-3">{{pageName}} With Google</div>
                </button>
            </div>
            <div class="col-md-12 text-center">
                <button class="bg-transparent mb-3 w-100 p-2" (click)="signInWithFB()">
                    <img class="float-left ml-3" src="assets/images/Icon awesome-facebook-f.png">
                    <div class="mr-3">{{pageName}} With Facebook</div>
                </button>
            </div>
        </div>

        <div class="col-md-12 mb-5 mt-2">
            <button *ngIf="signinPage" class="bg-transparent border-0 text-info float-md-right"
                (click)="switchPage(RESET)">
                Forgot your password?
            </button>
            <button *ngIf="resetPage" class="bg-transparent border-0 text-info float-md-right"
                (click)="switchPage(SIGNIN)">
                Back to signin
            </button>
        </div>
    </div>

</div>