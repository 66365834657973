import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { MatchComponent } from './match/match.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JobListingComponent } from './job-listing/job-listing.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UploadModalComponent } from './modal/upload-modal/upload-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { ImprovementComponent } from './improvement/improvement.component';
import { LoginComponent as LoginModalComponent} from './modal/login/login.component';
import { DashboardComponent} from './dashboard/dashboard.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { FooterComponent } from './footer/footer.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    MatchComponent,
    JobListingComponent,
    UploadModalComponent,
    LoginModalComponent,
    ImprovementComponent,
    DashboardComponent,
    TermsComponent,
    PolicyComponent,
    EmailVerifyComponent,
    PasswordResetComponent,
    FooterComponent,
    ContactUsComponent,
    LoginComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FontAwesomeModule,
    NgxDropzoneModule,
    HttpClientModule,
    SocialLoginModule,
    NgxPaginationModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '963334596348-lskrgakvts5fh6st8gtbokcim2a27gh5.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('680530232627264')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
