<div class="container">
    <jobstarter-header></jobstarter-header>
    <div class="row start-row pt-5 pb-5 bg-blue text-white">
        <div class="col-md-8 offset-md-2 bg-main text-center">
            <h2>Your Resume Report</h2>
            <h1 class="color-orange">{{average}}%</h1>
        </div>
    </div>
    <div class="row jd-row pt-5 pb-5 bg-grey">
        <div class="col-md-10 offset-md-1 pt-1 pb-1">
            <span class="bg-lightblue text-white p-2">Job Description</span>
        </div>
        <div class="col-md-9 offset-md-1">
            <div *ngIf="loadingJobDescription" class="text-center">
                <div class="spinner-border text-primary">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div *ngIf="!loadingJobDescription" class="bg-white p-4 mh-600 overflow-scroll" [innerHTML]="jobContent">
            </div>
        </div>
        <div class="col-md-2">
            <div *ngIf="!loadingJobDescription" class="bg-white p-4">
                <div class="mb-3">
                    <h6><strong>Well</strong></h6>
                    {{wellCount}} item(s)
                    <div class="p-2 bg-success"></div>
                </div>
                <div>
                    <h6><strong>Need</strong></h6>
                    {{needCount}} item(s)
                    <div class="p-2 bg-danger"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row free-consultant-row bg-blue text-white text-center">
        <div class="col-md-8 offset-md-2 bg-main text-center">
            <h2>Free 20 min Consultation </h2>
            <button class="btn-default mt-5 btn-orange">Book Now</button>
        </div>
    </div>
    <jobstarter-footer></jobstarter-footer>
</div>