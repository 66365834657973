<div class="container m-0">
    <div class="row">
        <div class="col-12 bg-blue px-4 pt-4 d-md-none d-flex">
            <div class="col-6">
                <a routerLink="/" href>
                    <img src="assets/images/jobstarter.png" width="100%">
                </a>
            </div>
            <div class="col-6 text-right pt-1">
                <button class="text-white bg-blue border-0" (click)="logout()">Sign Out</button>
            </div>
        </div>
        <div class="col-md-3 bg-blue d-none d-md-block" >
            <div class="d-flex flex-column vh-100 p-4">
                <div class="text-center">
                    <a routerLink="/" href>
                        <img src="assets/images/jobstarter.png" width="100%">
                    </a>
                </div>
                <ul class="mt-4 ml-5 list-unstyled">
                    <li>
                        <img src="assets/images/Untitled-1.png" width="30">
                        <button class="ml-5 text-white bg-blue border-0">RESUME</button>
                    </li>
                </ul>
                <div class="text-center mt-auto">
                    <p class="text-white">{{userName}}</p>
                    <button class="text-white bg-blue border-0" (click)="logout()">Sign Out</button>
                </div>
            </div>
        </div>
        <div class="col-md-9 bg-light d-flex flex-column justify-content-betweencontent">
            <h1 class="mt-5 ml-5">Recent Uploads</h1>
            <div *ngIf="loadingResumes" class="text-center">
                <div class="spinner-border text-primary">
                    <span class="sr-only"></span>
                </div>
            </div>
            <ng-container *ngIf="!loadingResumes">
                <div *ngFor="let resume of infoList.items | paginate: config">
                    <div class="row ml-5 m-3 bg-white text-center border border-dark" id="resumes-div">
                        <a class="col-md-3 mt-3 text-info" target="_blank"
                            href="https://api.jobster.io/files/jobstarter-resumes/{{resume.pdfFile}}">{{resume.fileName}}</a>
                        <p class="col-md-2 mt-3 text-danger"><b>{{resume.percentage}}% Matches</b></p>
                        <p class="col-md-2 mt-3">{{resume.timestamp | date:'d MMM y'}}</p>
                        <div class="col-md-5 p-0 d-flex justify-content-center">
                            <button class="btn btn-primary text-center m-3 px-2 py-1" type="button"
                                (click)="goToMatchPage(resume.resumeId)">MATCHING</button>
                            <button class="btn btn-primary text-center m-3 px-2 py-1" type="button"
                                (click)="goToImprovePage(resume.resumeId, resume.sampleJobId)">IMPROVE NOW</button>
                        </div>
                    </div>
                </div>
                <pagination-controls *ngIf="infoList.count" class="text-center mt-auto"
                    [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
            </ng-container>
        </div>
    </div>
</div>
