import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../constants';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Router } from '@angular/router';
@Component({
  selector: 'jobstarter-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  SIGNIN = 'Sign In';
  SIGNUP = 'Create Account';
  RESET = 'Reset Password';

  pageName = this.SIGNIN;
  signinPage = this.pageName === this.SIGNIN;
  signupPage = this.pageName === this.SIGNUP;
  resetPage = this.pageName === this.RESET;
  sentVerification = false;
  sentReset = false;
  errorsignin = false;
  errorsignup = false;
  errorReset = false;
  formInfo = new Map();
  signupErrorMessage = '';

  @Output() event: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: SocialAuthService) { }
  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      this.thirdPartyLogin(user);
    });
  }

  switchPage(page: string) {
    this.pageName = page;
    this.signinPage = page === this.SIGNIN;
    this.signupPage = page === this.SIGNUP;
    this.resetPage = page === this.RESET;
    this.errorsignup = false;
    this.errorsignin = false;
    this.sentVerification = false;
    this.sentReset = false;
  }

  fieldChange(event: any, field: string) {
    if (event.target.value) {
      this.formInfo.set(field, event.target.value);
    } else {
      this.formInfo.delete(field);
    }
  }

  verifyForm() {
    if (!this.formInfo.has('email')) {
      return false;
    } else {
      const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const email = this.formInfo.get('email');
      if (!emailPattern.test(String(email).toLowerCase())) {
        return false;
      }
    }
    if (!this.resetPage) {
      if (!this.formInfo.has('password')) {
        return false;
      }
    }
    if (this.signupPage) {
      if (!this.formInfo.has('firstname')) {
        return false;
      }
    }
    return true;
  }

  async sendData() {
    if (this.verifyForm()) {
      if (this.signinPage) {
        this.login();
      } else if (this.signupPage) {
        this.signup();
      } else if (this.resetPage) {
        this.reset();
      }
    } else if (this.signinPage) {
      this.errorsignin = true;
    } else if (this.signupPage) {
      this.errorsignup = true;
    }
  }

  async login() {
    const formData = new FormData();
    formData.append('email', this.formInfo.get('email'));
    formData.append('password', this.formInfo.get('password'));
    try {
      const res: any = await this.http.post(Constants.API_HOST + 'login',
        formData).toPromise();
      localStorage.setItem('user', JSON.stringify(res.user));
      this.errorsignin = false;
      this.router.navigate([Constants.PAGE_DASHBOARD, res.user.id]);
    } catch (error) {
      this.errorsignin = true;
    }
  }

  async signup() {
    const formData = new FormData();
    formData.append('email', this.formInfo.get('email'));
    formData.append('password', this.formInfo.get('password'));
    formData.append('firstName', this.formInfo.get('firstname'));
    try {
      await this.http.post(Constants.API_HOST + 'signup',
        formData).toPromise();
      this.sentVerification = true;
      this.errorsignup = false;
    } catch (error) {
      this.signupErrorMessage = error.error.Error;
      this.errorsignup = true;
    }
  }

  async reset() {
    const formData = new FormData();
    formData.append('email', this.formInfo.get('email'));
    try {
      await this.http.post(Constants.API_HOST + 'generateResetPasswordToken',
        formData).toPromise();
      this.sentReset = true;
      this.errorReset = false;
    } catch (error) {
      this.errorReset = true;
    }
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  async thirdPartyLogin(user: any) {
    try {
      const formData = new FormData();
      formData.append('firstName', user.firstName);
      formData.append('password', user.id);
      formData.append('email', user.email);
      formData.append('provider', user.provider);
      formData.append('photo', user.photoUrl);
      const res: any = await this.http.post(Constants.API_HOST + 'thirdPartyLogin', formData).toPromise();
      localStorage.setItem('user', JSON.stringify(res.user));
      setTimeout(() => {
        this.router.navigate([Constants.PAGE_DASHBOARD, res.user.id]);
      }, 1000);
    } catch (error) {
      this.errorsignin = true;
    }
  }
}
