<div class="container vh-100">
    <jobstarter-header></jobstarter-header>
    <div class="row start-row bg-blue text-white h-100">
        <div class="col-md-4 offset-md-4 text-center mt-5">
            <ng-container *ngIf="resetFailed || resetFailed === null">
                <h2 class="mt-5">
                    Forget your password?
                </h2>
                <p>Don't worry! Enter your new password below!</p>
                <div class="form-group text-left">
                    <label for="newPassword">New Password</label>
                    <input type="password" class="form-control" id="newPassword" (change)="inputChange($event)">
                </div>
                <div class="form-group text-left">
                    <label for="confirmPassword">Confirm Password</label>
                    <input type="password" class="form-control" id="confirmPassword" (change)="inputChange($event)"
                        [class.is-invalid]="newPassword && confirmedPassword && !passwordMatch">
                    <div class="invalid-feedback">
                        Password doesn't match.
                    </div>
                </div>
                <p *ngIf="resetFailed" class="mb-0 text-danger">We are not able to reset your password, please try again</p>
                <button class="btn-default mt-4" type="button" (click)="resetPassword()"
                    [disabled]="!passwordMatch">Reset</button>
            </ng-container>
            <ng-container *ngIf="resetFailed === false">
                <h2 class="mt-5">
                    Password updated
                </h2>
                <img src="assets/images/Icon awesome-check-circle.png" width="70" class="my-4">
                <p class="my-3">Your password has been updated!!</p>
                <button class="btn-default mt-4" type="button" (click)="backToLogin()"
                    >login</button>
            </ng-container>
        </div>
    </div>
    <jobstarter-footer></jobstarter-footer>
</div>
