import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../constants';
import { LoginComponent } from '../modal/login/login.component';

@Component({
  selector: 'jobstarter-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: any;
  userId = '';
  @Input() loginName = '';

  constructor(
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.user = localStorage.getItem('user');
    if (this.user) {
      this.user = JSON.parse(this.user);
      this.loginName = this.user.firstName;
    } else {
      this.loginName = 'Login';
    }
  }

  openLogin() {
    if (this.user) {
      const routerRes = this.router.navigate([Constants.PAGE_DASHBOARD, this.user.id]);
      routerRes.then(x => {
        if (!x) {
          this.initialize();
        }
      })
    } else {
      this.modalService.open(LoginComponent);
    }
  }
}