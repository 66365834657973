<div class="container">
    <div>
        <div class="job-listing-table">
            <div class="row job-row" *ngFor="let job of currentListing | paginate: config">
                <div class="col-lg-4">
                    <div class="hide-long-text">
                        <a (click)="goToJobPage(job.url)" title="{{job.position}}">
                            <span>{{job.position}}</span>
                        </a>
                    </div>
                    <div class="hide-long-text">{{job.company}}</div>
                    <div class="hide-long-text">{{job.location}}</div>
                </div>
                <div class="col-lg-1 text-lg-center">
                    {{job.formatedPostTimestamp}}
                </div>
                <div class="col-lg-1 text-lg-center">
                    {{job.jobType}}
                </div>
                <div class="color-orange col-lg-2 text-lg-center">
                    {{job.percentage}}% Matches
                </div>
                <div class="col-lg-4 text-lg-center">
                    <button class="btn-default mr-3 mb-2" (click)="goToJobPage(job.url)" target="_blank">Apply</button>
                    <button class="btn-default text-nowrap" (click)="goToImprovmentPage(job.id)">Improve Now</button>
                </div>
            </div>
        </div>
        <pagination-controls class="text-center mt-auto" [previousLabel]="labels.previousLabel"
            [nextLabel]="labels.nextLabel" (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
</div>