import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadModalComponent } from '../modal/upload-modal/upload-modal.component';

@Component({
  selector: 'jobstarter-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private modalService: NgbModal) { }
  ngOnInit(): void {
    window.location.href = 'https://jobstarter.io';
  }

  openModal() {
    const modalRef = this.modalService.open(UploadModalComponent);
  }
}
