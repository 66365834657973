<div class="container">
    <jobstarter-header></jobstarter-header>
    <div class="row">
        <div class="col-md-8 offset-md-2 mt-5 mb-5">
            <h1>
                Terms & Condition
            </h1>
            <p>
                Jobstarter.io helps jobseeker find jobs on Jobstarter by matching their resume to the posted job
                descriptions supported by our advanced machine learning algorithm based system.
            </p>
            <p>
                By using Jobstarter,
            </p>
            <ol>
                <li>Job search engine can match resume to the current posted jobs across multiple job listing platforms.</li>
                <li>Grading engine can rank jobseekers resume and provide the score in percentage to demonstrate the competitiveness of the resume uploaded by jobseekers.</li>
                <li>Skill matching engine can identify the advantages and disadvantages between resume and job description.</li>
            </ol>
            <h3>
                Eligibility
            </h3>
            <p>
                We do not knowingly collect any Personal Information from children under the age of 16. If you are under the age of 16, please do not submit any Personal Information through the Website and Services. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Website and Services without their permission. If you have reason to believe that a child under the age of 16 has provided Personal Information to us through the Website and Services, please contact us. You must also be at least 16 years of age to consent to the processing of your Personal Information in your country (in some countries we may allow your parent or guardian to do so on your behalf).
            </p>
            <h3>
                Accounts and membership
            </h3>
            <p>
                If you create an account on the Website using email, Google, or Facebook, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re- register for our Services. We may block your email address and Internet protocol address to prevent further registration.
            </p>
            <h3>
                Data Collection
            </h3>
            <p>
                For the purpose of providing functional feature (matching, scoring, searching, suggestion, tips), Jobstarter.io may scan your resume and collect your information that may include but not limited to your email address, content, and social media URL. We use data solely in Jobstarter but not shared to the third party.
            </p>
            <h3>
                Links to other resources
            </h3>
            <p>
                Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website and Services. Your linking to any other off-site resources is at your own risk.
            </p>
            <h3>
                Intellectual property rights
            </h3>
            <p>
                "Intellectual Property Rights" means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by the Operator or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with the Operator. All trademarks, service marks, graphics and logos used in connection with the Website and Services, are trademarks or registered trademarks of the Operator or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Website and Services may be the trademarks of other third parties. Your use of the Website and Services grants you no right or license to reproduce or otherwise use any of the Operator or third party trademarks.
            </p>
            <h3>
                Changes and amendments
            </h3>
            <p>
                We reserve the right to modify this Agreement or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.
            </p>
            <h3>
                Acceptance of these terms
            </h3>
            <p>
                You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services.
            </p>
            <h3>
                Contacting us
            </h3>
            <p>
                If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to support@jobstarter.io
            </p>
            <p>
                This document was last updated on January 4, 2021
            </p>
        </div>
    </div>
    <jobstarter-footer></jobstarter-footer>
</div>