<div class="container vh-100">
    <jobstarter-header></jobstarter-header>
    <div class="row start-row pt-sm-5 pt-3 bg-blue text-white h-100">
        <div class="col-sm-8 offset-sm-2 text-left mt-sm-5">
            <h1 class="mt-5">
                Hi, How we can help?
            </h1>
            <h4 class="mt-5 font-weight-light">Email us to let us know your feedback or concerns</h4>
            <h4 class="mt-5 font-weight-light">
                <fa-icon [icon]="faEnvelope" class="mr-4"></fa-icon>
                <a href="mailto:contact@jobstarter.io">contact@jobstarter.io</a>
            </h4>
        </div>
    </div>
    <jobstarter-footer></jobstarter-footer>
</div>