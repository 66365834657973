import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'jobstarter-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {
  disableSubmit = true;
  isChecked = false;
  files: File[] = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  toggleCheckbox() {
    this.isChecked = !this.isChecked;
    this.disableSubmit = !this.isChecked || this.files.length === 0;
  }

  onSelect(event: any) {
    this.files = [];
    this.files.push(...event.addedFiles);
    this.disableSubmit = !this.isChecked || this.files.length === 0;
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
    this.disableSubmit = !this.isChecked || this.files.length === 0;
  }

  async uploadFile() {
    const formData = new FormData();
    for (const file of this.files) {
      formData.append('file', file);
    }
    if (this.authService.isLoggedIn()) {
      formData.append('userId', this.authService.getUserId());
    }
    try {
      const res: any = await this.http.post(Constants.API_HOST + 'resumeUpload', formData).toPromise();
      this.activeModal.close();
      this.router.navigate([Constants.PAGE_MATCH + res.id]);
    } catch (error) {
      console.log(error);
    }
  }
}
