import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../constants';

@Component({
  selector: 'jobstarter-improvement',
  templateUrl: './improvement.component.html',
  styleUrls: ['./improvement.component.scss']
})
export class ImprovementComponent implements OnInit {
  jobId: any;
  resumeId: any;
  jobContent: any;
  wellCount = 0;
  needCount = 0;
  average = 0;
  loadingJobDescription = true;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.initPageData();
    this.getJobDescription();
    this.getSampleJobPercentage();
  }

  initPageData() {
    this.resumeId = this.route.snapshot.paramMap.get('resumeId') ? this.route.snapshot.paramMap.get('resumeId') : '';
    this.jobId = this.route.snapshot.paramMap.get('jobId') ? this.route.snapshot.paramMap.get('jobId') : '';
  }

  async getSampleJobPercentage() {
    try {
      const res: any = await this.http.get(Constants.API_HOST + 'getSampleJobPercentage',
        { params: { resumeId: this.resumeId } }).toPromise();
      const sampleJobs = [...res.items];
      this.average = Math.ceil((sampleJobs[0].percentage + sampleJobs[1].percentage + sampleJobs[2].percentage) / 3);
    } catch (error) {
      console.log(error);
    }
  }

  async getJobDescription() {
    this.loadingJobDescription = true;
    try {
      const res: any = await this.http.get(Constants.API_HOST + 'getJobDescription',
        { params: { resumeId: this.resumeId, jobId: this.jobId } }).toPromise();
      this.jobContent = res.job;
      this.wellCount = res.wellCount;
      this.needCount = res.needCount;
      this.loadingJobDescription = false;
    } catch (error) {
      console.log(error);
    }
  }

}
