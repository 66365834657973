<div class="row header-row px-0 px-md-3">
    <div class="col-6">
        <a routerLink="/">
            <img src="assets/images/jobstarter.png" width="250">
        </a>
    </div>
    <div class="col-6 text-right">
        <button (click)="openLogin()">{{loginName}}</button>
    </div>
</div>
